
                        @import "@/assets/scss/_variables.scss";
                        @import "@/assets/scss/_mixins.scss";
                    

.info-window {
    position: absolute;
    background: #FFF;
    width: 200px;
    height: 200px;
    z-index: 1000;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
