
                        @import "@/assets/scss/_variables.scss";
                        @import "@/assets/scss/_mixins.scss";
                    

.slide-fade-enter-active {
   transition: all .3s ease;
}

.slide-fade-leave-active {
   transition: all .4s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.slide-fade-enter, .slide-fade-leave-to {
   transform: translateX(10px);
   opacity: 0;
}

.filter_list .active a {
   background: $abellio-red;
}
