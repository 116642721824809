
                        @import "@/assets/scss/_variables.scss";
                        @import "@/assets/scss/_mixins.scss";
                    

.timetable_element {
   background: $light-grey;
   margin-bottom: 0;
   padding-bottom: 30px;
}

.hfs {
   background: red;
}

.extra-button {
   float: right;
   margin-right: 10px;
   margin-bottom: -50px;
   z-index: 8;
}
