
                        @import "@/assets/scss/_variables.scss";
                        @import "@/assets/scss/_mixins.scss";
                    

.job_offers_map_wrapper {
   background: #333333;
}

.job_offers_map_wrapper .vue-map {
   opacity: 0;
}

.job_offers_map_wrapper.is_visible .vue-map {
   opacity: 1;
}
